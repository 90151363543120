import { useStaticQuery, graphql } from 'gatsby'

export const useBackground = () => {
    const { bgImages } = useStaticQuery(
        graphql`
            query ImagesQuery{
                bgImages: allFile(
                    filter: {
                        extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
                        absolutePath: { regex: "/images/background/" }
                    }
                ) {
                    edges {
                        node {
                            name
                            childImageSharp {
                                fluid(maxWidth: 1600, quality: 80) {
                                    aspectRatio
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
            }        
        `
    )
    return bgImages
}