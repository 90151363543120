import React, { Fragment, useState, useContext, useEffect } from 'react';
import { graphql } from 'gatsby';
import md5 from 'md5';
import Cookies from 'js-cookie';
import StoreContext from '~/layouts/StoreContext';
import { getShopifyCustomer } from '~/helpers/requests/customer-requests';

export const Criteo = ({
  type,
  criteoProductId,
  orderId,
  collectionProductIds,
  lineItems,
  readyToFireCriteo,
}) => {
  const {
    store: { checkout, asyncScriptLoad },
  } = useContext(StoreContext);
  const [toggle, setToggle] = useState(false);
  const buildLineItems = () => {
    return checkout.lineItems.map(item => {
      console.log('atob', typeof item.variant.product.id);
      const productId = item.variant.product.id.replace('gid://shopify/Product/', '');
      const varId = item.variant.id.replace('gid://shopify/ProductVariant/', '');
      return {
        id: `shopify_us_${productId}_${varId}`,
        price: item.variant.price.amount,
        quantity: item.quantity,
      };
    });
  };
  const buildLineItemsCheckout = () => {
    return lineItems.map(item => {
      return {
        id: `shopify_us_${item.node.product.id.replace(
          'gid://shopify/Product/',
          ''
        )}_${item.node.variant.id.replace('gid://shopify/ProductVariant/', '')}`,
        price: Number(item.node.variant.price.amount).toFixed(2),
        quantity: item.node.quantity,
      };
    });
  };
  useEffect(() => {
    if (window && asyncScriptLoad) {
      if (!window.criteo_q) {
        console.log('no criteo');
      }
      window.criteo_q = window.criteo_q || [];
      var deviceType = /iPad/.test(navigator.userAgent)
        ? 't'
        : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent)
        ? 'm'
        : 'd';
      if (readyToFireCriteo === false) return;
      if (type === 'orderConfirm' && Cookies.get('HAS_SENT_CONFIRM') === orderId) return;
      if (Cookies.get('KEPT_SESS')) {
        getShopifyCustomer(Cookies.get('KEPT_SESS')).then(res => {
          let userEmail;
          if (res.data.data.customer) {
            userEmail = md5(res.data.data.customer.email);
          }
          if (type === 'homepage') {
            if (userEmail) {
              window.criteo_q.push(
                { event: 'setAccount', account: 83023 },
                { event: 'setSiteType', type: deviceType },
                {
                  event: 'setEmail',
                  email: userEmail,
                  hash_method: 'none',
                },
                { event: 'viewHome' }
              );
            } else {
              window.criteo_q.push(
                { event: 'setAccount', account: 83023 },
                { event: 'setSiteType', type: deviceType },
                { event: 'viewHome' }
              );
            }
          } else if (type === 'collection') {
            if (collectionProductIds) {
              if (userEmail) {
                window.criteo_q.push(
                  { event: 'setAccount', account: 83023 },
                  { event: 'setSiteType', type: deviceType },
                  {
                    event: 'setEmail',
                    email: userEmail,
                    hash_method: 'none',
                  },
                  {
                    event: 'viewList',
                    item: collectionProductIds,
                  }
                );
              } else {
                window.criteo_q.push(
                  { event: 'setAccount', account: 83023 },
                  { event: 'setSiteType', type: deviceType },
                  {
                    event: 'viewList',
                    item: collectionProductIds,
                  }
                );
              }
            }
          } else if (type === 'product') {
            if (criteoProductId) {
              if (userEmail) {
                window.criteo_q.push(
                  { event: 'setAccount', account: 83023 },
                  { event: 'setSiteType', type: deviceType },
                  {
                    event: 'setEmail',
                    email: userEmail,
                    hash_method: 'none',
                  },
                  { event: 'viewItem', item: criteoProductId }
                );
              } else {
                window.criteo_q.push(
                  { event: 'setAccount', account: 83023 },
                  { event: 'setSiteType', type: deviceType },
                  { event: 'viewItem', item: criteoProductId }
                );
              }
            }
          } else if (type === 'cart') {
            console.log({
              event: 'viewBasket',
              item: buildLineItems(),
            });
            if (userEmail) {
              window.criteo_q.push(
                { event: 'setAccount', account: 83023 },
                { event: 'setSiteType', type: deviceType },
                {
                  event: 'setEmail',
                  email: userEmail,
                  hash_method: 'none',
                },
                {
                  event: 'viewBasket',
                  item: buildLineItems(),
                }
              );
            } else {
              window.criteo_q.push(
                { event: 'setAccount', account: 83023 },
                { event: 'setSiteType', type: deviceType },
                {
                  event: 'viewBasket',
                  item: buildLineItems(),
                }
              );
            }
          } else if (type === 'orderConfirm') {
            if (orderId && lineItems?.length) {
              const expiryDate = new Date();
              expiryDate.setDate(expiryDate.getDate() + 1);
              Cookies.set('HAS_SENT_CONFIRM', orderId, {
                expires: expiryDate,
              });
              if (userEmail) {
                console.log(
                  'CRITEO FIRE!!',
                  { event: 'setAccount', account: 83023 },
                  { event: 'setSiteType', type: deviceType },
                  {
                    event: 'setEmail',
                    email: userEmail,
                    hash_method: 'none',
                  },
                  {
                    event: 'trackTransaction',
                    id: orderId,
                    item: buildLineItemsCheckout(),
                  }
                );
                window.criteo_q.push(
                  { event: 'setAccount', account: 83023 },
                  { event: 'setSiteType', type: deviceType },
                  {
                    event: 'setEmail',
                    email: userEmail,
                    hash_method: 'none',
                  },
                  {
                    event: 'trackTransaction',
                    id: orderId,
                    item: buildLineItemsCheckout(),
                  }
                );
              } else {
                window.criteo_q.push(
                  { event: 'setAccount', account: 83023 },
                  { event: 'setSiteType', type: deviceType },
                  {
                    event: 'trackTransaction',
                    id: orderId,
                    item: buildLineItemsCheckout(),
                  }
                );
              }
            }
          } else {
            if (userEmail) {
              window.criteo_q.push(
                { event: 'setAccount', account: 83023 },
                { event: 'setSiteType', type: deviceType },
                {
                  event: 'setEmail',
                  email: userEmail,
                  hash_method: 'none',
                },
                { event: 'viewPage' }
              );
            } else {
              window.criteo_q.push(
                { event: 'setAccount', account: 83023 },
                { event: 'setSiteType', type: deviceType },
                { event: 'viewPage' }
              );
            }
          }
        });
      } else {
        if (type === 'homepage') {
          window.criteo_q.push(
            { event: 'setAccount', account: 83023 },
            { event: 'setSiteType', type: deviceType },
            { event: 'viewHome' }
          );
        } else if (type === 'collection') {
          if (collectionProductIds) {
            window.criteo_q.push(
              { event: 'setAccount', account: 83023 },
              { event: 'setSiteType', type: deviceType },
              {
                event: 'viewList',
                item: collectionProductIds,
              }
            );
          }
        } else if (type === 'product') {
          if (criteoProductId) {
            window.criteo_q.push(
              { event: 'setAccount', account: 83023 },
              { event: 'setSiteType', type: deviceType },
              { event: 'viewItem', item: criteoProductId }
            );
          }
        } else if (type === 'cart') {
          window.criteo_q.push(
            { event: 'setAccount', account: 83023 },
            { event: 'setSiteType', type: deviceType },
            {
              event: 'viewBasket',
              item: buildLineItems(),
            }
          );
        } else if (type === 'orderConfirm') {
          if (orderId && lineItems?.length) {
            const expiryDate = new Date();
            expiryDate.setDate(expiryDate.getDate() + 1);
            Cookies.set('HAS_SENT_CONFIRM', orderId, {
              expires: expiryDate,
            });
            console.log(
              'CRITEO FIRE!!',
              { event: 'setAccount', account: 83023 },
              { event: 'setSiteType', type: deviceType },
              {
                event: 'trackTransaction',
                id: orderId,
                item: buildLineItemsCheckout(),
              }
            );
            window.criteo_q.push(
              { event: 'setAccount', account: 83023 },
              { event: 'setSiteType', type: deviceType },
              {
                event: 'trackTransaction',
                id: orderId,
                item: buildLineItemsCheckout(),
              }
            );
          }
        } else {
          window.criteo_q.push(
            { event: 'setAccount', account: 83023 },
            { event: 'setSiteType', type: deviceType },
            { event: 'viewPage' }
          );
        }
      }
    }
  }, [
    asyncScriptLoad,
    toggle,
    type,
    criteoProductId,
    orderId,
    collectionProductIds,
    lineItems,
    readyToFireCriteo,
  ]);
  return null;
};
