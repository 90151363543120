import React from 'react';
import Image from 'gatsby-image';
import styled from 'styled-components';

import { useBackground } from '../../hooks/use-background';
import { CenteredContainer } from '~/components/.base/containers';
import { WoodenBorder } from './border';

export const HeroContainer = styled(CenteredContainer)`
    width: 100%;
    &.fullHeight {
        height: calc(100vh - var(--footer-height, 60px));
        @media screen and (max-width: 767px) {
            height: 100vh;
        }
    }

    position: relative;
    background-size: cover;
    background-position: center;
    ${props =>
        props.size && props.image
            ? `background-image: url(${props.image});`
            : `.hero-img {
				width: 100%; height: 100%;
			}`}
    .hero__bg {
        position: absolute !important; // Need to overwrite the gatsby image style
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .gatsby-image-wrapper {
        width: 100%;
    }
    .border {
        position: absolute !important;
        width: 100%;
        bottom: -25px;
        left: 0;
        z-index: 3;
    }
    .hero-details {
        ${props => (props.size ? '' : 'position: absolute;')}
        width: 100%;
        z-index: 2;
        h1,
        h3,
        p {
            color: #fff;
            text-align: center;
            max-width: 600px;
            margin: auto;
            margin-bottom: 1rem;
        }
    }
    .cont {
        z-index: 2;
    }
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: radial-gradient(
            ellipse at center,
            rgba(0, 0, 0, 0.8) 0%,
            rgba(0, 0, 0, 0.4) 100%
        );
        z-index: 2;
    }
    @media screen and (max-width: 767px) {
        ${props =>
            !props.size && props.image
                ? `
				background-image: url(${props.image});
				height: 70vh;
				background-size: cover;
				.hero-img {
					display: none;
				}`
                : ``}
        ${props =>
            props.imageFluid
                ? `
				background-image: url(${props.imageFluid.src});
				height: 70vh;
				background-size: cover;
				.hero__bg {
					display: none;
				}`
                : ``}
		.border {
            bottom: -5px;
        }
    }
`;

const Hero = ({ size, image, imageFluid, alt, wrapperClass, children, noOverlay, style }) => {
    const bgImages = useBackground().edges;
    if (imageFluid) {
        return (
            <HeroContainer
                className={`${wrapperClass} ${size === 'full' ? 'fullHeight' : null}`}
                size={size == 'full' ? 'width: calc(100vh - 96px)' : null}
                imageFluid={imageFluid}
            >
                {!noOverlay ? <div className="overlay" /> : null}
                <Image className="hero__bg" fluid={imageFluid} alt={alt} />
                <WoodenBorder top="100%" />
                {children}
            </HeroContainer>
        );
    } else {
        return (
            <HeroContainer
                className={`${wrapperClass} ${size === 'full' ? 'fullHeight' : null}`}
                size={size == 'full' ? 'width: calc(100vh - 96px)' : null}
                image={image}
                style={style}
            >
                {!noOverlay ? <div className="overlay" /> : null}
                {size !== 'full' ? <img className="hero-img" src={image} alt={alt} /> : null}
                <WoodenBorder top="100%" />
                {children}
            </HeroContainer>
        );
    }
};

export default Hero;
