import React, { Fragment, useState, useEffect, useRef } from 'react';
import { graphql } from 'gatsby';
import { Formik } from 'formik';
import axios from 'axios';
import Loader from 'react-loader-spinner';
import { css } from 'styled-components';
import Image from 'gatsby-image';
import ReCAPTCHA from "react-google-recaptcha";
import MailingListPopup from '~/components/JoinMailingList/MailingListPopup';

import { appendFormData, getImage } from '~/helpers';
import { breakpoint } from '../constants/Breakpoint';
import { Criteo } from '~/components/Criteo/Criteo';
import SEO from '~/components/seo';
import { DeriveHeaderClasses } from '~/components/Navigation';
import Hero from '~/components/.base/hero';
import {
  HeadingLarge,
  Heading,
  BodyText,
  BodyTextAlt,
  LargeStencil,
} from '~/components/.base/headings';
import { FormControl, FormControlSelect, FormSuccess, FormError } from '~/components/.base/inputs';
import { RedButton } from '~/components/.base/buttons';
import { Grid, Row, Col, FlexBox, BackgroundDiv } from '~/components/.base/containers';
import ArrowDown from '~/images/icons/arrow_normal_down.svg';
import './contact.scss';

const initialErrors = {
  name: null,
  message: null,
  location: null,
  email: null,
};

const Contact = ({ data, location }) => {
  console.log(data);
  const [formSuccess, setFormSuccess] = useState(false);
  const [formError, setFormError] = useState('');
  const [initialValues, setInitialValues] = useState({
    message: '',
    location: '',
    email: '',
    name: '',
  });
  const [hero, setHero] = useState(null);
  const [errors, setErrors] = useState(initialErrors);
  const [isReCaptchaValid, setIsReCaptchaValid] = useState(false);
  const [captchaToken, setCaptchaToken] = useState();

  const messageFieldRef = useRef(null);
  const locationFieldRef = useRef(null);
  const emailFieldRef = useRef(null);
  const nameFieldRef = useRef(null);

  const STATIC_LOCATIONS = [{
    location: 'Corporate',
    email: 'jlyons@cousinsmainelobster.com',
  },
  {
    location: 'Online Shop',
    email: 'orders@cousinsmainelobster.com'
  }];

  useEffect(() => {
    setHero(
      data.contentfulPage
        ? data.contentfulPage.contentModules.find(m => m.__typename === 'ContentfulHeroSection')
        : null
    );
  }, [data]);

  const handleReCaptchaChange = (value) => {
    setIsReCaptchaValid(!!value);
    setCaptchaToken(value);
  }

  const submitForm = async (values, { setSubmitting, resetForm }) => {
    setFormError('');
    setFormSuccess(false);
    setSubmitting(true);

    let locationEmail = null;
    const staticLocation = STATIC_LOCATIONS.find(l => l.location === values.location);

    if (staticLocation) {
      locationEmail = staticLocation.email;
    } else {
      locationEmail = data.allContentfulFoodTruckPage.edges.find(edge => edge.node.title === values.location)
        .node
        .emailAddress;
    }

    values.storeLocation = values.location;
    values.location = locationEmail;

    const formData = appendFormData(values);
    let verifyCaptchaTokenRes = null;

    try {
      verifyCaptchaTokenRes = await axios.post(`${process.env.GATSBY_SERVER_API_URL}/api/forms/verify-captcha`, {
        token: captchaToken
      });
    } catch (e) {
      setSubmitting(false);
      setFormError(e?.message);
    }

    if (!verifyCaptchaTokenRes?.data?.success) {
      setSubmitting(false);
      setFormError('Please check the recaptcha and try again');

      return;
    }

    axios
      .post(`${process.env.GATSBY_SERVER_API_URL}/api/forms/contact`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Accept: 'application/json',
        },
      })
      .then(res => {
        if (window && window.ga) {
          window.ga('send', 'event', 'form', 'submission', 'Contact Form Submission', '1');
        }
        setSubmitting(false);
        setInitialValues(values);
        setInitialValues({
          name: '',
          email: '',
          location: '',
          message: '',
        });
        setFormSuccess(true);
        if (window.fbq) {
          window.fbq('track', 'Contact');
        }
      })
      .then(() => {
        resetForm({
          name: '',
          email: '',
          location: '',
          message: '',
        });
      })
      .catch(err => {
        console.log(err);
        setSubmitting(false);
        setFormError(err.message);
      });
  };
  let uniqueTrucks = [
    ...new Set(
      data.allContentfulFoodTruckPage.edges
        .map(edge => edge.node.title)
        // https://trello.com/c/Tj8qsKbe/596-remove-clearwater-beach-choices-from-contact-forms
        .filter(title => !title.toLowerCase().includes('clearwater beach, fl'))
        .sort()
    ),
  ];
  console.log(uniqueTrucks);
  uniqueTrucks = uniqueTrucks.map(truck => ({
    truck,
    email: data.allContentfulFoodTruckPage.edges.find(edge => edge.node.title === truck).node
      .emailAddress,
  }));
  return (
    <Fragment>
      <MailingListPopup />
      <SEO title="Contact Us" />
      <DeriveHeaderClasses location={location} />
      <Criteo />
      <div className="contact">
        <LargeStencil
          as="span"
          css={css`
            transform: rotate(90deg);
            top: 6%;
            left: 251px;
            z-index: 4;
            transform-origin: 0 0;
            @media ${breakpoint.maxMd} {
              display: none;
            }
          `}
          aria-hidden="true"
        >
          Contact
        </LargeStencil>
        <Hero size="full" imageFluid={hero ? hero.heroSlides[0].slideImage.fluid : null} alt="">
          <div className="hero-details">
            <HeadingLarge>Contact</HeadingLarge>
            <BodyText></BodyText>
          </div>
        </Hero>
        <div
          css={css`
            position: relative;
            padding: 3rem 0 150px;
          `}
        >
          <BackgroundDiv
            css={css`
              width: 943px;
              left: 0;
              top: 0;
              z-index: -1;
              transform: translateX(-27.47%);
            `}
          >
            <Image fluid={getImage(data.bgImages.edges, 'blurred_smudge_3@3x')} alt="" />
          </BackgroundDiv>
          <div
            css={css`
              position: absolute;
              right: -108px;
              bottom: 0;
              width: 440px;
              z-index: -10;
            `}
          >
            <Image fluid={getImage(data.contactImages.edges, 'contact_hugging')} alt="" />
          </div>
          <div className="contact-form-cont">
            <Heading>
              General <span className="red">inquiries</span>
            </Heading>
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validateOnChange={false}
              validateOnBlur={false}
              validate={values => {
                const errs = {};

                if (!values.message) {
                  errs.message = 'Required';
                  if (messageFieldRef?.current) messageFieldRef.current.focus();
                }
                if (!values.location) {
                  errs.location = 'Required';
                  if (locationFieldRef?.current) locationFieldRef.current.focus();
                }
                if (values.email === '') {
                  errs.email = 'Required';
                  if (emailFieldRef?.current) emailFieldRef.current.focus();
                } else if (
                  values.email &&
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errs.email = 'Invalid email address';
                  if (emailFieldRef?.current) emailFieldRef.current.focus();
                }
                if (!values.name) {
                  errs.name = 'Required';
                  if (nameFieldRef?.current) nameFieldRef.current.focus();
                }
                setErrors(errs);
                return errs;
              }}
              onSubmit={submitForm}
            >
              {({
                values,
                errs,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                /* and other goodies */
              }) => (
                <form className="contact-form">
                  <BodyTextAlt md="0 0 2rem">* indicates a required field</BodyTextAlt>
                  <Grid>
                    <Row>
                      <Col size={1}>
                        <FormControl>
                          <label htmlFor="name">
                            Name
                            <span className="red">*</span>
                          </label>
                          <input
                            id="name"
                            name="name"
                            className={`field${!!errors.name ? ' is-invalid' : ''}`}
                            placeholder="Enter your full name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name}
                            ref={nameFieldRef}
                            aria-describedby="nameError"
                            autoComplete="name"
                          />
                          <div id="nameError" className="error">
                            {errors.name}
                          </div>
                        </FormControl>
                      </Col>
                      <Col size={1}>
                        <FormControl>
                          <label htmlFor="email">
                            Email
                            <span className="red">*</span>
                          </label>
                          <input
                            id="email"
                            name="email"
                            className={`field${!!errors.email ? ' is-invalid' : ''}`}
                            placeholder="Enter your email address"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            ref={emailFieldRef}
                            aria-describedby="emailError"
                            autoComplete="email"
                          />
                          <div id="emailError" className="error">
                            {errors.email}
                          </div>
                        </FormControl>
                      </Col>
                    </Row>
                    <Row>
                      <Col size={1}>
                        <FormControlSelect
                          svgTop="45px"
                          svgRight="calc(2rem + 20px)"
                          w="calc(100% - 2rem)"
                        >
                          <label htmlFor="location">
                            Location
                            <span className="red">*</span>
                          </label>
                          <select
                            id="location"
                            name="location"
                            className={!!errors.location ? ' is-invalid' : ''}
                            placeholder="Country"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.location}
                            ref={locationFieldRef}
                            aria-describedby="locationError"
                            autoComplete="address-level2"
                          >
                            <option value="">Select Location</option>
                            {STATIC_LOCATIONS.map(l => (
                              <option value={l.location}>{l.location}</option>
                            ))}
                            {uniqueTrucks.map(truck => (
                              <option value={truck.truck}>{truck.truck}</option>
                            ))}
                          </select>
                          <ArrowDown
                            style={{
                              pointerEvents: 'none',
                            }}
                          />
                          <div id="locationError" className="error">
                            {errors.location}
                          </div>
                        </FormControlSelect>
                      </Col>
                    </Row>
                    <Row>
                      <Col size={1}>
                        <FormControl>
                          <label htmlFor="message">
                            Message
                            <span className="red">*</span>
                          </label>
                          <textarea
                            id="message"
                            name="message"
                            className={`field${!!errors.name ? ' is-invalid' : ''}`}
                            rows="5"
                            placeholder="Enter your message here, we're all ears!"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.message}
                            ref={messageFieldRef}
                            aria-describedby="messageError"
                          />
                          <div id="messageError" className="error">
                            {errors.message}
                          </div>
                        </FormControl>
                      </Col>
                    </Row>
                    {formSuccess ? (
                      <FormSuccess>
                        <BodyTextAlt role="alert">
                          Thank you for reaching out! We will get back to you shortly.
                        </BodyTextAlt>
                      </FormSuccess>
                    ) : null}
                    {formError.length ? (
                      <FormError>
                        <BodyTextAlt>{formError}</BodyTextAlt>
                      </FormError>
                    ) : null}
                    <FlexBox justify="center">
                      <ReCAPTCHA
                        className="recaptcha"
                        sitekey={process.env.GATSBY_GOOGLE_RECAPTCHA_KEY}
                        onChange={handleReCaptchaChange}
                      />
                    </FlexBox>
                    <FlexBox justify="center">
                      <RedButton
                        type="submit"
                        onClick={handleSubmit}
                        disabled={!isReCaptchaValid || (isReCaptchaValid && isSubmitting)}
                        h="50px"
                        pd="0 35px"
                      >
                        {isSubmitting ? (
                          <Fragment>
                            <Loader type="TailSpin" color="#152622" height={16} width={16} />
                            Loading...
                          </Fragment>
                        ) : formSuccess ? (
                          'Sent!'
                        ) : (
                          'Submit'
                        )}
                      </RedButton>
                    </FlexBox>
                  </Grid>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export const query = graphql`
  query ContactPages {
    contentfulPage(title: { eq: "Contact" }) {
      title
      contentModules {
        ... on ContentfulHeroSection {
          title
          heroSlides {
            title
            slideImage {
              fluid(maxWidth: 1600) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            mobileImage {
              fluid(maxWidth: 600) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            buttonText
            whiteHeaderText
            useOverlay
            overlayOpacity
          }
        }
      }
    }
    allContentfulFoodTruckPage {
      edges {
        node {
          title
          handle
          emailAddress
        }
      }
    }
    contactImages: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
        absolutePath: { regex: "/images/contact/" }
      }
    ) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              aspectRatio
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    bgImages: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
        absolutePath: { regex: "/images/background/" }
      }
    ) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              aspectRatio
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
export default Contact;
